<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.agentMember.list')"
    class="px-5 py-3"
  >
    <div class="d-flex align-items-center justify-space-between mt-2">
      <div>{{ $t('agentMember.viewTotal') }}: {{ meta.total }}</div>
    </div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
          <th class="primary--text">
            {{ $t('agent.uuid') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.name') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.phone') }}
          </th>
          <th class="primary--text">
            {{ $t('agentRegistration.email') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.memberType') }}
          </th>
          <th class="primary--text">
            {{ $t('agentMember.memberStart') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`agent-${index}`">
          <td>{{ index + 1 }}</td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.uuid"
            ></base-detail-button>
          </td>
          <td>{{ item.uuid }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.membership ? item.membership.type : '' }}</td>
          <td>
            {{
              item.membership && item.membership.start_at
                ? format(new Date(item.membership.start_at), 'dd MMMM yyyy HH:mm')
                : ''
            }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions', 'cities'],
  data() {
    return {
      format,
    };
  },
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.agentMember.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
  },
};
</script>
